import React, { useState, useEffect, useRef, useMemo } from "react"
import Layout from "../../components/Layout"
import Img from "gatsby-image"
import SEO from "../../components/SEO/SEO"
import SeeAlso from "../../components/SeeAlso/SeeAlso"

import NavBar from "../../components/NavBar/NavBar"
import { isSSR } from "../../components/Script/script"
import {
  Title,
  SubTitle,
  Columns,
  Column,
  Button,
  StaticButton,
  Control,
  Field,
  Select,
} from "../../StyleComponents/styles"
import { FlexDiv } from "../../StyleComponents/pagesStyle"

const GoogleAds = React.lazy(() => import("../../components/ADS/ADS"))

function BarbariansPage(props) {
  const { node } = props.pageContext
  // console.log(data.allNamesListGeneratorJson)
  let [result1, setresult1] = useState([])
  let gender = useRef("1")

  useEffect(() => {
    generate()
  }, [])

  function generate() {
    randomizer(
      node.nm1,
      node.nm2,
      node.nm3,
      node.nm4,
      node.nm5,
      node.nm6,
      node.nm7,
      node.nm8
    )
  }

  function randomizer(nm1, nm2, nm3, nm4, nm5, nm6, nm7, nm8) {
    let NamesData = []
    let names
    for (let i = 0; i < 10; i++) {
      let rnd, rnd2, rnd3, rnd4, rnd5, rnd6, rnd7
      if (gender.current.value === "2") {
        rnd = Math.floor(Math.random() * nm5.length)
        rnd2 = Math.floor(Math.random() * nm6.length)
        rnd3 = Math.floor(Math.random() * nm8.length)
        if (i < 3) {
          while (rnd < 5) {
            rnd = Math.floor(Math.random() * nm5.length)
          }
          names = nm5[rnd] + nm6[rnd2] + nm8[rnd3]
        } else if (i < 8) {
          rnd4 = Math.floor(Math.random() * nm6.length)
          if (rnd2 < 5) {
            while (rnd4 < 5) {
              rnd4 = Math.floor(Math.random() * nm6.length)
            }
          }
          rnd5 = Math.floor(Math.random() * nm7.length)
          names = nm5[rnd] + nm6[rnd2] + nm7[rnd5] + nm6[rnd4] + nm8[rnd3]
        } else {
          rnd4 = Math.floor(Math.random() * nm6.length)
          if (rnd2 < 5) {
            while (rnd4 < 5) {
              rnd4 = Math.floor(Math.random() * nm6.length)
            }
          }
          rnd5 = Math.floor(Math.random() * nm7.length)
          rnd6 = Math.floor(Math.random() * nm6.length)
          if (rnd2 < 5 || rnd4 < 5) {
            while (rnd6 < 5) {
              rnd6 = Math.floor(Math.random() * nm6.length)
            }
          }
          rnd7 = Math.floor(Math.random() * nm7.length)
          names =
            nm5[rnd] + nm6[rnd2] + nm7[rnd5] + nm6[rnd4] + nm7[rnd7] + nm6[rnd6]
        }
      } else {
        rnd = Math.floor(Math.random() * nm2.length)
        rnd2 = Math.floor(Math.random() * nm1.length)
        rnd3 = Math.floor(Math.random() * nm4.length)
        if (i < 3) {
          names = nm2[rnd] + nm1[rnd2] + nm4[rnd3]
        } else if (i < 8) {
          rnd4 = Math.floor(Math.random() * nm1.length)
          if (rnd < 3) {
            while (rnd4 < 3) {
              rnd4 = Math.floor(Math.random() * nm1.length)
            }
          }
          rnd5 = Math.floor(Math.random() * nm3.length)
          names = nm2[rnd] + nm1[rnd2] + nm3[rnd5] + nm1[rnd4] + nm4[rnd3]
        } else {
          rnd4 = Math.floor(Math.random() * nm1.length)
          if (rnd < 3) {
            while (rnd4 < 3) {
              rnd4 = Math.floor(Math.random() * nm1.length)
            }
          }
          rnd5 = Math.floor(Math.random() * nm3.length)
          rnd6 = Math.floor(Math.random() * nm1.length)
          if (rnd < 3 || rnd4 < 3) {
            while (rnd6 < 3) {
              rnd6 = Math.floor(Math.random() * nm1.length)
            }
          }
          rnd7 = Math.floor(Math.random() * nm3.length)
          names =
            nm2[rnd] +
            nm1[rnd2] +
            nm3[rnd5] +
            nm1[rnd4] +
            nm3[rnd7] +
            nm1[rnd6] +
            nm4[rnd3]
        }
      }
      NamesData.push(names)
    }
    setresult1(NamesData)
  }

  return (
    <Layout location={props.location}>
      <SEO
        title={`Random ${node.name} Generator`}
        description={`Random ${node.name} Generator, Generate Random ${node.name} just on click generate, Simple wee help you find the perfect ${node.name}.`}
        keywords={[
          `${node.name} generator, ${node.name} generator, random ${node.name} generator,${node.name} male name generator,${node.name} female name generator, random ${node.name} male female, random male names, random female names,random ${node.name}`,
        ]}
      />
      <NavBar
        listPages={useMemo(() => {
          return [
            { name: "Generator", link: "/generator/" },
            { name: `${node.name} Generator`, link: `/${node.path}/` },
          ]
        }, [node.name, node.path])}
      />
      <section className="container">
        <Title>{node.name} generator</Title>
        <SubTitle>Random {node.name} Generator</SubTitle>
        <br />

        <Columns>
          <Column>
            <FlexDiv textAlign="center" minHeight="400px">
              <Button
                className="mb-3"
                borderColor="#1da1f2"
                color="#1da1f2"
                hoverColor="white"
                hoverBorderColor="#1da1f2"
                hoverBackgroundColor="#1da1f2"
                fontSize="1.5rem"
                value="Generate"
                onClick={generate}
              >
                Generate
              </Button>
              <Field justifyContent="center">
                <Control>
                  <StaticButton background="white">Gender </StaticButton>
                </Control>
                <Control>
                  <Select>
                    <select
                      className="staticSelect"
                      aria-label="genre"
                      ref={gender}
                    >
                      <option value="1">Male</option>
                      <option value="2">Female</option>
                    </select>
                  </Select>
                </Control>
              </Field>

              <br />
              <div>
                {result1.map((resu, index) => {
                  return (
                    <p className="h5" key={index}>
                      {resu}
                    </p>
                  )
                })}
              </div>
            </FlexDiv>
          </Column>
          <Column>
            {/* google ads UnitPediaRight */}
            {!isSSR && (
              <React.Suspense fallback={<div />}>
                <GoogleAds
                  slot="1189740279"
                  data-ad-format="auto"
                  data-ad-layout-key="-gi+g+7v-1q-6r"
                  data-full-width-responsive="true"
                />
              </React.Suspense>
            )}
          </Column>
        </Columns>

        <br />
        <FlexDiv maxWidth="250px" margin="auto">
          <Img
            fluid={node.img.childImageSharp.fluid}
            alt={`random ${node.name} generator`}
          />
        </FlexDiv>
        <br />
        <div>
          <h3>Random {node.name} Generator</h3>
          <p>
            Simply press on the button "generate" to generate a random{" "}
            {node.name}, wee help you to find the perfect {node.name}
          </p>
        </div>
        <br />
        <SeeAlso seeAlsoList={node.SeeAlso} />
        <br />
      </section>
    </Layout>
  )
}

export default BarbariansPage
